
import { Injectable, Compiler } from '@angular/core';

import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IRestResponse } from '../core/interface/response.interface';
import { Environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { DataService } from './data.service';
import { EUserType, EUserTypes } from '../enums/user.enum';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private restService: RestService,
    private compiler: Compiler,
    private dataService: DataService,
    private router: Router) { }

  public forceLogout() {
    this.compiler.clearCache();
    // this.dataService.clearOut();
    localStorage.clear();
    this.router.navigate(['/login']);
  }


  public login(data: any): Observable<IRestResponse<any>> {
    return this.restService
      .oauthLogin(Environment.baseUrl + `/oauth/token`, data, false).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public logout(): Observable<IRestResponse<any>> {
    return this.restService
      .oauthLogout(Environment.baseUrl + `/oauth/logout`, false).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  get isLoggedIn() {
    if (this.dataService.getLocalStorageItem('access_token') !== '') {
      return true;
    }
    return false;
  }
  get userType() {
    return this.dataService.getLocalStorageItem('user_type') || ''
  }

  get isSuperAdmin() {
    if (this.userType == EUserType.SUPER_ADMIN) {
      return true;
    }
    return false;
  }
  get isCompanyAdmin() {
    if (this.userType == EUserType.COMPANY_ADMIN) {
      return true;
    }
    return false;
  }
  get isBranchAdmin() {
    if (this.userType == EUserType.BRANCH_ADMIN) {
      return true;
    }
    return false;
  }
  get isGPEmployee() {
    if (this.isLoggedIn &&
      (this.userType == EUserTypes.DEMO_MANAGER
        || this.userType == EUserTypes.STOCKYARD_MANAGER
        || this.userType == EUserTypes.PDI_MANAGER
        || this.userType == EUserTypes.CASHIER
        || this.userType == EUserTypes.WORKSHOP_MANAGER
        || this.userType == EUserTypes.MOBILE_SERVICE_VAN_MANAGER
        || this.userType == EUserTypes.FRONT_OFFICE_DESK
      )) {
      return true;
    }
    return false;
  }
  get isOtherEmployee() {
    if (this.isLoggedIn &&
      (this.userType == EUserTypes.SERVICE_CENTER_MANAGER
        || this.userType == EUserTypes.SHOWROOM_MANAGER
        || this.userType == EUserTypes.CUSTOMER_SERVICE_MANAGER
        || this.userType == EUserTypes.USED_CARS_MANAGER
      )) {
      return true;
    }
    return false;
  }
  get isShowRoomManager() {
    if (this.userType == EUserTypes.SHOWROOM_MANAGER) {
      return true;
    }
    return false;
  }

  get isServiceCenterManager() {
    if (this.userType == EUserTypes.SERVICE_CENTER_MANAGER) {
      return true;
    }
    return false;
  }

  get isCashier() {
    if (this.userType == EUserTypes.CASHIER) {
      return true;
    }
    return false;
  }

  get isPDIManager() {
    if (this.userType == EUserTypes.PDI_MANAGER) {
      return true;
    }
    return false;
  }

  get isDemoManager() {
    if (this.userType == EUserTypes.DEMO_MANAGER) {
      return true;
    }
    return false;
  }

  get isStockYardManager() {
    if (this.userType == EUserTypes.STOCKYARD_MANAGER) {
      return true;
    }
    return false;
  }
  get isCustomerServiceManager() {
    if (this.userType == EUserTypes.CUSTOMER_SERVICE_MANAGER) {
      return true;
    }
    return false;
  }
  get isUsedCarManager() {
    if (this.userType == EUserTypes.USED_CARS_MANAGER) {
      return true;
    }
    return false;
  }
  get isWorkShopManager() {
    if (this.userType == EUserTypes.WORKSHOP_MANAGER) {
      return true;
    }
    return false;
  }
  get isMobileServiceVanManager() {
    if (this.userType == EUserTypes.MOBILE_SERVICE_VAN_MANAGER) {
      return true;
    }
    return false;
  }
  get isFrontOfficeDesk() {
    if (this.userType == EUserTypes.FRONT_OFFICE_DESK) {
      return true;
    }
    return false;
  }
}
